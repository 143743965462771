<template>
    <div>
        <div class="row mb-3">
            <div class="col-md-6">
                <personal-id-card v-model="model" ref="personalIdCard" />
            </div>
            <div class="col-md-6">
                <passport v-model="model" ref="passport" />
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <motor-registration-card v-model="model" ref="motorRegistrationCard" />
            </div>
            <div class="col-md-6">
                <vehicle-registation-card v-model="model" ref="vehicleRegistrationCard" />
            </div>
        </div>
    </div>
</template>
<script>
import MotorRegistrationCard from './motor-registration-card.vue';
import Passport from './passport.vue';
import PersonalIdCard from "./personal-id-card.vue";
import VehicleRegistationCard from './vehicle-registation-card.vue';

export default {
    name: "documentAttachment",
    props: ["value", "validate", "clear"],
    components: {
        PersonalIdCard,
        Passport,
        MotorRegistrationCard,
        VehicleRegistationCard
    },
    data() {
        return {
            model: {},
        };
    },
    created() {
        this.model = this.value;
    }
};
</script>
