<template>
    <div>
        <div class="row">
            <div class="col-md-10">
                <div class="row">
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="row mb-3">
                                    <label
                                        class="form-label col-form-label col-md-4"
                                        >{{ $t("customer.nameKh") }}</label
                                    >
                                    <div class="col-md-8">
                                        <input
                                            v-model.trim="
                                                model.spouse_name_kh
                                            "
                                            ref="spouse_name_kh"
                                            type="text"
                                            class="form-control"
                                            :class="{
                                                'is-invalid': errors.has(
                                                    'spouse_name_kh'
                                                )
                                            }"
                                        />
                                        <div
                                            class="invalid-feedback"
                                            v-if="
                                                errors.has('spouse_name_kh')
                                            "
                                        >
                                            {{
                                                errors.first("spouse_name_kh")
                                            }}
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <label
                                        class="form-label col-form-label col-md-4"
                                        >{{ $t("customer.nameEn") }}</label
                                    >
                                    <div class="col-md-8">
                                        <input
                                            v-model.trim="
                                                model.spouse_name_en
                                            "
                                            type="text"
                                            class="form-control"
                                            :class="{
                                                'is-invalid':
                                                    errors.has(
                                                        'spouse_name_en'
                                                    )
                                            }"
                                        />
                                        <div
                                            class="invalid-feedback"
                                            v-if="
                                                errors.has('spouse_name_en')
                                            "
                                        >
                                            {{
                                                errors.first("spouse_name_en")
                                            }}
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <label
                                        class="form-label col-form-label col-md-4"
                                    >
                                        {{ $t("customer.sex") }}
                                    </label>
                                    <div class="col-md-8">
                                        <div class="form-control-plaintext">
                                            <div
                                                class="form-check form-check-inline"
                                            >
                                                <input
                                                    type="radio"
                                                    class="form-check-input"
                                                    id="male"
                                                    v-model="model.sp_gender"
                                                    value="Male"
                                                    :class="{
                                                        'is-invalid':
                                                            errors.has(
                                                                'sp_gender'
                                                            )
                                                    }"
                                                />
                                                <label
                                                    for="male"
                                                    class="form-check-label"
                                                    >Male</label
                                                >
                                            </div>
                                            <div
                                                class="form-check form-check-inline"
                                            >
                                                <input
                                                    type="radio"
                                                    class="form-check-input"
                                                    id="female"
                                                    v-model="model.sp_gender"
                                                    value="Female"
                                                    :class="{
                                                        'is-invalid':
                                                            errors.has(
                                                                'sp_gender'
                                                            )
                                                    }"
                                                />
                                                <label
                                                    for="female"
                                                    class="form-check-label"
                                                    >Female</label
                                                >
                                            </div>
                                            <br />
                                            <div
                                                class="invalid-feedback"
                                                v-if="errors.has('sp_gender')"
                                            >
                                                {{ errors.first("sp_gender") }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <label
                                        class="form-label col-form-label col-md-4"
                                        >{{ $t("customer.dateOfBirth") }}</label
                                    >
                                    <div class="col-md-8">
                                        <DatePicker
                                            type="date"
                                            :value="model.sp_date_of_birth"
                                            style="width:100%"
                                            placeholder="DD-MM-YYYY"
                                            format="dd-MM-yyyy"
                                            @on-change="onSpChangeDateOfBirth"
                                        ></DatePicker>
                                        <div
                                            class="invalid-feedback"
                                            v-if="errors.has('sp_date_of_birth')"
                                        >
                                            {{ errors.first("sp_date_of_birth") }}
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <label
                                        class="form-label col-form-label col-md-4"
                                    >
                                        {{ $t("customer.occupation") }}
                                    </label>
                                    <div class="col-md-8"><Select
                                            v-model="model.sp_occupation"
                                            :class="{
                                                'ivu-form-item-error':
                                                    errors.has('sp_occupation')
                                            }"
                                            filterable
                                            clearable
                                        >
                                            <OptionGroup
                                                v-for="(sector,
                                                index) in occupations"
                                                :label="
                                                    sector.sector_code +
                                                        '|' +
                                                        (locale == 'kh'
                                                            ? sector.sector_kh
                                                            : sector.sector_en)
                                                "
                                                :key="index"
                                            >
                                                <Option
                                                    v-for="(option,
                                                    i) in sector.occupations"
                                                    :value="option.id"
                                                    :key="i"
                                                    >{{
                                                        option.occupation_code +
                                                            "-" +
                                                            (locale == "kh"
                                                                ? option.occupation_kh
                                                                : option.occupation_en)
                                                    }}</Option>
                                            </OptionGroup>
                                        </Select>
                                        <div
                                            class="invalid-feedback"
                                            v-if="errors.has('sp_occupation')"
                                        >
                                            {{ errors.first("sp_occupation") }}
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <label
                                        class="form-label col-form-label col-md-4"
                                        >{{ $t("customer.contactNo") }}</label
                                    >
                                    <div class="col-md-8">
                                        <input
                                            v-model.trim="model.sp_contact_no"
                                            type="text"
                                            class="form-control"
                                            :class="{
                                                'is-invalid': errors.has(
                                                    'sp_contact_no'
                                                )
                                            }"
                                        />
                                        <div
                                            class="invalid-feedback"
                                            v-if="errors.has('sp_contact_no')"
                                        >
                                            {{ errors.first("sp_contact_no") }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row mb-3">
                                    <label
                                        class="form-label col-form-label col-md-4"
                                    >
                                        {{ $t("customer.province") }}
                                    </label>
                                    <div class="col-md-8">
                                        <select
                                            v-model="model.sp_province_code"
                                            class="form-control"
                                            :class="{
                                                'is-invalid': errors.has(
                                                    'sp_province_code'
                                                )
                                            }"
                                            @change="
                                                spProvinceChange(
                                                    model.sp_province_code
                                                )
                                            "
                                            @input="
                                                model.sp_district_code = model.sp_commune_code = model.sp_village_code = null
                                            "
                                        >
                                            <option
                                                :value="null"
                                                disabled
                                                selected
                                                style="display: none;"
                                                >{{ $t("select") }}</option
                                            >
                                            <option
                                                v-for="(province,
                                                index) in provinces"
                                                :key="index"
                                                :value="province.province_code"
                                            >
                                                {{ province.name_en }} |
                                                {{ province.name_kh }}
                                            </option>
                                        </select>
                                        <div
                                            class="invalid-feedback"
                                            v-if="errors.has('sp_province_code')"
                                        >
                                            {{ errors.first("sp_province_code") }}
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <label
                                        class="form-label col-form-label col-md-4"
                                    >
                                        {{ $t("customer.district") }}
                                    </label>
                                    <div class="col-md-8">
                                        <ts-loading-banner
                                            :loading="province_change"
                                        >
                                            <select
                                                v-model="model.sp_district_code"
                                                class="form-control"
                                                :class="{
                                                    'is-invalid': errors.has(
                                                        'sp_district_code'
                                                    )
                                                }"
                                                @change="
                                                    spDistrictChange(
                                                        model.sp_district_code
                                                    )
                                                "
                                                @input="
                                                    model.sp_commune_code = model.sp_village_code = null
                                                "
                                            >
                                                <option
                                                    :value="null"
                                                    disabled
                                                    selected
                                                    style="display: none;"
                                                    >{{ $t("select") }}</option
                                                >
                                                <option
                                                    v-for="(district,
                                                    index) in spDistricts"
                                                    :key="index"
                                                    :value="
                                                        district.district_code
                                                    "
                                                >
                                                    {{ district.name_en }} |
                                                    {{ district.name_kh }}
                                                </option>
                                            </select>
                                        </ts-loading-banner>
                                        <div
                                            class="invalid-feedback"
                                            v-if="errors.has('sp_district_code')"
                                        >
                                            {{ errors.first("sp_district_code") }}
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <label
                                        class="form-label col-form-label col-md-4"
                                    >
                                        {{ $t("customer.commune") }}
                                    </label>
                                    <div class="col-md-8">
                                        <ts-loading-banner
                                            :loading="district_change"
                                        >
                                            <select
                                                v-model="model.sp_commune_code"
                                                class="form-control"
                                                :class="{
                                                    'is-invalid': errors.has(
                                                        'sp_commune_code'
                                                    )
                                                }"
                                                @change="
                                                    spCommuneChange(
                                                        model.sp_commune_code
                                                    )
                                                "
                                                @input="
                                                    model.sp_village_code = null
                                                "
                                            >
                                                <option
                                                    :value="null"
                                                    disabled
                                                    selected
                                                    style="display: none;"
                                                    >{{ $t("select") }}</option
                                                >
                                                <option
                                                    v-for="(commune,
                                                    index) in spCommunes"
                                                    :key="index"
                                                    :value="
                                                        commune.commune_code
                                                    "
                                                >
                                                    {{ commune.name_en }} |
                                                    {{ commune.name_kh }}
                                                </option>
                                            </select>
                                        </ts-loading-banner>
                                        <div
                                            class="invalid-feedback"
                                            v-if="errors.has('sp_commune_code')"
                                        >
                                            {{ errors.first("sp_commune_code") }}
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <label
                                        class="form-label col-form-label col-md-4"
                                    >
                                        {{ $t("customer.village") }}
                                    </label>
                                    <div class="col-md-8">
                                        <ts-loading-banner
                                            :loading="commune_change"
                                        >
                                            <select
                                                v-model="model.sp_village_code"
                                                class="form-control"
                                                :class="{
                                                    'is-invalid': errors.has(
                                                        'sp_village_code'
                                                    )
                                                }"
                                            >
                                                <option
                                                    :value="null"
                                                    disabled
                                                    selected
                                                    style="display: none;"
                                                    >{{ $t("select") }}</option
                                                >
                                                <option
                                                    v-for="(village,
                                                    index) in spVillages"
                                                    :key="index"
                                                    :value="
                                                        village.village_code
                                                    "
                                                >
                                                    {{ village.name_en }} |
                                                    {{ village.name_kh }}
                                                </option>
                                            </select>
                                        </ts-loading-banner>
                                        <div
                                            class="invalid-feedback"
                                            v-if="errors.has('sp_village_code')"
                                        >
                                            {{ errors.first("sp_village_code") }}
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-4">
                                    <label
                                        class="form-label col-form-label col-md-4"
                                    >
                                        {{ $t("customer.street") }}
                                    </label>
                                    <div class="col-md-8">
                                        <input
                                            type="text"
                                            v-model="model.sp_street"
                                            class="form-control"
                                            :class="{
                                                'is-invalid': errors.has(
                                                    'sp_street'
                                                )
                                            }"
                                        />
                                        <div
                                            v-if="errors.has('sp_street')"
                                            class="invalid-feedback"
                                        >
                                            {{ errors.first("sp_street") }}
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-4">
                                    <label
                                        class="form-label col-form-label col-md-4"
                                    >
                                        {{ $t("customer.house") }}
                                    </label>
                                    <div class="col-md-8">
                                        <input
                                            type="text"
                                            v-model="model.sp_house"
                                            class="form-control"
                                            :class="{
                                                'is-invalid': errors.has(
                                                    'sp_house'
                                                )
                                            }"
                                        />
                                        <div
                                            v-if="errors.has('sp_house')"
                                            class="invalid-feedback"
                                        >
                                            {{ errors.first("sp_house") }}
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <label
                                        class="form-label col-form-label col-md-4"
                                    >
                                        {{ $t("customer.country") }}
                                    </label>
                                    <div class="col-md-8">
                                        <textarea
                                            type="text"
                                            v-model="model.country"
                                            class="form-control"
                                            :class="{
                                                'is-invalid': errors.has(
                                                    'country'
                                                )
                                            }"
                                            rows="3"
                                        ></textarea>
                                        <div
                                            v-if="errors.has('country')"
                                            class="invalid-feedback"
                                        >
                                            {{ errors.first("country") }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <fieldset>
                            <legend class="text-blue">
                                {{ $t("customer.ssn") }}
                            </legend>
                            <div class="row mb-3">
                                <label
                                    class="form-label col-form-label col-md-5"
                                    >{{ $t("customer.documentType") }}</label
                                >
                                <div class="col-md-7">
                                    <select
                                        v-model.number="model.sp_doc_type_id"
                                        class="form-select"
                                        :class="{
                                            'is-invalid': errors.has(
                                                'sp_doc_type_id'
                                            )
                                        }"
                                    >
                                        <option :value="null" selected>{{
                                            $t("select")
                                        }}</option>
                                        <option
                                            v-for="(doc_type,
                                            index) in documentTypes"
                                            :key="index"
                                            :value="doc_type.doc_type_id"
                                        >
                                            {{ doc_type.doc_type_en }} |
                                            {{ doc_type.doc_type_kh }}
                                        </option>
                                    </select>
                                    <div
                                        class="invalid-feedback"
                                        v-if="errors.has('sp_doc_type_id')"
                                    >
                                        {{ errors.first("sp_doc_type_id") }}
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <label
                                    class="form-label col-form-label col-md-5"
                                    >{{ $t("customer.nidOrpassport") }}</label
                                >
                                <div class="col-md-7">
                                    <input
                                        v-model.trim="model.sp_ssn_no"
                                        type="text"
                                        class="form-control"
                                        :class="{
                                            'is-invalid': errors.has('sp_ssn_no')
                                        }"
                                    />
                                    <div
                                        class="invalid-feedback"
                                        v-if="errors.has('sp_ssn_no')"
                                    >
                                        {{ errors.first("sp_ssn_no") }}
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <label
                                    class="form-label col-form-label col-md-5"
                                    >{{
                                        $t("customer.nidOrpassportIssuedDate")
                                    }}</label
                                >
                                <div class="col-md-7">
                                    <DatePicker
                                        type="date"
                                        :value="model.sp_issued_date"
                                        style="width:100%"
                                        placeholder="DD-MM-YYYY"
                                        format="dd-MM-yyyy"
                                        @on-change="onSpChangeIssuedDate"
                                    ></DatePicker>
                                    <div
                                        class="invalid-feedback"
                                        v-if="errors.has('sp_issued_date')"
                                    >
                                        {{ errors.first("sp_issued_date") }}
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <label
                                    class="form-label col-form-label col-md-5"
                                    >{{
                                        $t("customer.nidOrpassportExpiryDate")
                                    }}</label
                                >
                                <div class="col-md-7">
                                    <DatePicker
                                        type="date"
                                        :value="model.sp_expiry_date"
                                        style="width:100%"
                                        placeholder="DD-MM-YYYY"
                                        format="dd-MM-yyyy"
                                        @on-change="onSpChangeExpiryDate"
                                    ></DatePicker>
                                    <div
                                        class="invalid-feedback"
                                        v-if="errors.has('sp_expiry_date')"
                                    >
                                        {{ errors.first("sp_expiry_date") }}
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-2">
                                <label
                                    class="form-label col-form-label col-md-5"
                                    >{{
                                        $t("customer.nidOrpassportIssuedAt")
                                    }}</label
                                >
                                <div class="col-md-7">
                                    <textarea
                                        v-model.trim="model.sp_issue_at"
                                        rows="7"
                                        class="form-control"
                                        :class="{
                                            'is-invalid': errors.has('sp_issue_at')
                                        }"
                                    ></textarea>
                                    <div
                                        class="invalid-feedback"
                                        v-if="errors.has('sp_issue_at')"
                                    >
                                        {{ errors.first("sp_issue_at") }}
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="d-flex flex-column align-items-center">
                    <ts-image-input
                        class="image-input"
                        :file="file"
                        @input="file => (model.sp_photo = file)"
                        :width="175"
                        :height="225"
                        crop
                    ></ts-image-input>
                    <ts-button @click="() => (showForm = true)" class="tw-mt-2">
                        <i class="fas fa-camera mr-2"></i>
                        {{ $t("customer.takePhoto") }}
                    </ts-button>
                    <div style="color:#cc4946;margin:4px 0 0">
                        {{ errors.first("sp_photo") }}
                    </div>
                </div>
            </div>
        </div>
        <!-- webcam -->
        <div v-if="showForm">
            <Modal
                :title="$t('customer.takePhoto')"
                v-model="showForm"
                draggable
                sticky
                scrollable
                :footer-hide="true"
                width="690"
            >
                <web-cam @close="getPhoto" />
            </Modal>
        </div>
    </div>
</template>

<script>
import { Errors } from "form-backend-validation";
import { mapActions, mapState } from "vuex";
import WebCam from "./web-cam";
import { isEmpty } from "lodash";

export default {
    name: "spouse",
    props: ["value", "validate", "clear"],
    components: {
        WebCam
    },
    data() {
        return {
            model: {},
            loadingPreData: false,
            showForm: false,
            errors: new Errors(),
            file: require("../../../../assets/staff-default-women.png"),
            marritalStatus: ["Single", "Married"],
            province_change: false,
            district_change: false,
            commune_change: false,
            spDistricts: [],
            spCommunes: [],
            spVillages: []
        };
    },
    created() {
        this.model = this.value;
    },
    computed: {
        ...mapState("creditOperation/customer", [
            "spPhotoBlob",
            "edit_data",
            "documentTypes",
            "provinces",
            "occupations"
        ]),
        locale() {
            return this.$root.$i18n.locale;
        }
    },
    methods: {
        ...mapActions("creditOperation/customer", [
            "getDistrict",
            "getCommune",
            "getVillage"
        ]),
        async getReligion() {
            this.religionOptions = null;
            let response = await this.fetchReligion();
            this.religionOptions = response.data.map(el => ({
                id: el.id,
                label: el.religion
            }));
        },
        getPhoto() {
            this.file = this.readBlob(this.spPhotoBlob);
            this.model.sp_photo = new File([this.spPhotoBlob], "sp_photo", {
                type: "image/jpeg"
            });
            this.showForm = false;
            this.$store.commit("creditOperation/customer/SET_SP_PHOTO_BLOB", "");
        },
        readBlob(blob) {
            return window.URL.createObjectURL(
                new Blob([blob], { type: "image/jpeg" })
            );
        },
        onSpChangeDateOfBirth(date) {
            this.model.sp_date_of_birth = date;
        },
        onSpChangeIssuedDate(date) {
            this.model.sp_issued_date = date;
        },
        onSpChangeExpiryDate(date) {
            this.model.sp_expiry_date = date;
        },
        setEditData() {
            if (!isEmpty(this.edit_data)) {
                this.model.sp_photo = this.edit_data.sp_photo;
                this.file = this.model.sp_photo;
                if (this.edit_data.sp_province_code) {
                    this.spProvinceChange(this.edit_data.sp_province_code);
                }
                if (this.edit_data.sp_district_code) {
                    this.spDistrictChange(this.edit_data.sp_district_code);
                }
                if (this.edit_data.sp_commune_code) {
                    this.spCommuneChange(this.edit_data.sp_commune_code);
                }
                this.model.sp_province_code = this.edit_data.sp_province_code;
                this.model.sp_district_code = this.edit_data.sp_district_code;
                this.model.sp_commune_code = this.edit_data.sp_commune_code;
                this.model.sp_village_code = this.edit_data.sp_village_code;
                this.model.sp_street = this.edit_data.sp_street;
                this.model.sp_house = this.edit_data.sp_house;
                this.model.country = this.edit_data.country;
            }
        },
        spProvinceChange(sp_province_code) {
            this.province_change = true;
            this.getDistrict(sp_province_code)
                .then(response => {
                    this.spDistricts = response.data;
                })
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.province_change = false;
                });
        },

        spDistrictChange(sp_district_code) {
            this.district_change = true;
            this.getCommune(sp_district_code)
                .then(response => {
                    this.spCommunes = response.data;
                })
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.district_change = false;
                });
        },

        spCommuneChange(sp_commune_code) {
            this.commune_change = true;
            this.getVillage(sp_commune_code)
                .then(response => {
                    this.spVillages = response.data;
                })
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.commune_change = false;
                });
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "CUSTOMER PROFILE",
                desc: not.text
            });
        }
    },
    watch: {
        validate: {
            handler() {
                this.errors = new Errors(this.validate.errors);
            },
            deep: true
        },
        clear: function(value) {
            if (value == true) {
                this.model = this.value;
                this.file = "";
            }
        },
        "model.sp_province_code": function(value) {
            if (value) {
                this.getDistrict(value);
            }
        },
        "model.sp_district_code": function(value) {
            if (value) {
                this.getCommune(value);
            }
        },
        "model.sp_commune_code": function(value) {
            if (value) {
                this.getVillage(value);
            }
        }
    }
};
</script>
